@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: 'Quicksand-Regular';
  font-size: 20px;
  color: #262626;
  font-weight: 400;
  line-height: 1.5;
}
.container {
  max-width: 1200px;
  width: 100%;
  margin: 50px auto 50px;
  padding: 0 15px;
}
img {
  max-width: 100%;
  width: auto;
  height: auto;
  max-height: 100%;
}