/* .styled-checkbox:checked+label:before {
    background: #e9d2a4;
}
.styled-checkbox+label:before {
    content: "";
    margin-right: 5px;
    display: inline-block;
    vertical-align: text-top;
    width: 19px;
    height: 19px;
    border: 1px solid #e4cea2;
    border-radius: 6px;
    background-color: #e9d2a4;
}
.styled-checkbox:checked+label:after {
    content: "";
    position: absolute;
    left: 7px;
    top: 5px;
    width: 6px;
    height: 11px;
    border: solid #931217;
    border-width: 0px 3px 3px 0px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.styled-checkbox.styled-checkboxBtn +label:before {
    margin-right: 15px;
}


.styled-check+label:before {
    content: "";
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    background-color: #fff;
}
.styled-check:checked+label:after {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    width: 10px;
    height: 10px;
    border-radius: 2px;
    background-color: #931217;
}
.styled-check.styled-checkboxBtn +label:before {
    margin-right: 15px;
} */


.setting-styled-checkbox:checked+label:before {
    background: #ed1c24;
}
.setting-styled-checkbox+label:before {
    content: "";
    margin-right: 5px;
    display: inline-block;
    vertical-align: text-top;
    width: 55px;
    height: 26px;
    border: 1px solid #e4cea2;
    border-radius: 100px;
    /* position: absolute; */
    background: #f6ecc8;
    right: 0;
    cursor: pointer;
}
.setting-styled-checkbox+label:after {
    content: "";
    position: absolute;
    top: 0px;
    right:32px;
    width: 28px;
    height: 28px;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.3s;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
}
.setting-styled-checkbox:checked+label:after {
    right: 0;
    top: 0px;
}